import { Button, Modal, UseModalState } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useTranslationContext } from "../../../hooks";

export const CantCancelModalRegular: React.FC<UseModalState> = (modalProps) => {
  const { t } = useTranslationContext();

  return (
    <Modal {...modalProps}>
      <h3 className={cn("mb-4")}>{t((d) => d.cant_cancel_tr_title)}</h3>
      <div className={cn("mb-4")} dangerouslySetInnerHTML={{ __html: t((d) => d.cant_cancel_tr_text) }} />
      <Button className={"w-full"} buttonType="primary" onClick={modalProps.close}>
        {t((d) => d.cant_cancel_tr_button)}
      </Button>
    </Modal>
  );
};
