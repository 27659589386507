import { FoAddress, FoBaseParams, FoContact, FoParty } from "@brenger/api-client";
import { ExtraSmall, IconInfoCircle, IconLoader, InputText, useForm, useModalState } from "@brenger/react";
import React from "react";
import { useTranslationContext } from "../../hooks";
import { trackAndTag } from "../../utils/eventTracking";
import { EditContactModal, MpContainer, MpFooter, MpFooterCta, PageHead, PhoneNumberModal } from "../components";
import { EditInstructionsField } from "../components/EditInstructionsField";
import { useContact } from "../hooks/useContact";
import { useDraftTr } from "../hooks/useDraftTr";
import { MpRouteComponentProps } from "../routes";
import cn from "classnames";

export const SharedContactPage: React.FC<MpRouteComponentProps> = (props) => {
  const partyId = props.match.params.id;
  const draftTr = useDraftTr({ partyId });
  const party = draftTr.data?.party as FoParty;
  const contact = useContact({ partyId });
  const { t } = useTranslationContext();
  const address = draftTr.data?.[party === "buyer" ? "delivery" : "pickup"].address;
  if (!draftTr.data || !contact.data || !address) {
    return (
      <>
        <PageHead title={t((d) => d.fo.contact_page.title)} sub={t((d) => d.fo.contact_page.sub_title)} />
        <MpContainer>
          <IconLoader />
        </MpContainer>
      </>
    );
  }
  return (
    <>
      <PageHead title={t((d) => d.fo.contact_page.title)} sub={t((d) => d.fo.contact_page.sub_title)} />
      <ContactForm
        partyId={partyId}
        party={party}
        address={address}
        contact={contact.data}
        isSubmitting={contact.isSubmitting}
        submit={contact.update}
      />
    </>
  );
};

interface ContactFormProps extends FoBaseParams {
  address: FoAddress;
  contact: FoContact;
  submit(c: FoContact): void;
  isSubmitting: boolean;
}

const ContactForm: React.FC<ContactFormProps> = ({ partyId, party, address, contact, submit, isSubmitting }) => {
  const { t } = useTranslationContext();
  const editModal = useModalState();
  const phoneModal = useModalState();

  const form = useForm({
    initialState: {
      ...contact,
      phone_number: contact.phone_number || "",
      instructions: contact.instructions || "",
    },
    persist: {
      type: "session",
      key: `contact_${partyId}`,
    },
    validators: {
      phone_number: (v) => {
        if (!v) return t((d) => d.fo.contact_page.fields.required);
        if (!/^[()+\d ]{9,}$/.test(v)) {
          return t((d) => d.fo.contact_page.fields.phone_number.error);
        }
        return false;
      },
    },
  });
  return (
    <>
      <MpContainer>
        <div className="flex justify-between">
          <h6>{t((d) => d.fo.contact_page.contact_person)}</h6>
          <button
            type={"button"}
            className="bg-transparent text-blue-600"
            onClick={() => {
              editModal.open();
              trackAndTag({ event: "FO Interaction", type: "Modal open - contact details", data: party, partyId });
            }}
          >
            <small>{t((d) => d.fo.contact_page.edit).toLowerCase()}</small>
          </button>
        </div>
        <div>
          {form.data.first_name.value} {form.data.last_name.value}
        </div>
        <div>{form.data.email.value}</div>
      </MpContainer>
      <MpContainer>
        <div className="flex gap-2 mb-3">
          <h6>{t((d) => d.fo.contact_page.fields.phone_number.label)}*</h6>
          <button type={"button"} className="bg-transparent" onClick={phoneModal.open}>
            <IconInfoCircle />
          </button>
        </div>
        <InputText
          placeholder={t((d) => d.fo.contact_page.fields.phone_number.placeholder)}
          className={cn("w-full", "px-4 py-3", "text-lg", "border-gray-400", {
            "border-red-300": !!form.data.phone_number.error && form.data.phone_number.isDirty,
          })}
          onChange={(v) => {
            form.set({ phone_number: v });
            trackAndTag({ event: "FO Interaction", type: `${party} update details`, data: "phone_number", partyId });
          }}
          value={form.data.phone_number.value}
        />
        {form.data.phone_number.error && form.data.phone_number.isDirty && (
          <div className="text-red-600">
            <ExtraSmall>{form.data.phone_number.error}</ExtraSmall>
          </div>
        )}
      </MpContainer>
      <MpContainer>
        <h6 className="mb-3">{t((d) => d.fo.contact_page.fields.instructions.label)}</h6>
        <EditInstructionsField
          value={form.data.instructions.value}
          setValue={(instructions) => {
            form.set({ instructions });
            trackAndTag({ event: "FO Interaction", type: `${party} update details`, data: "instructions", partyId });
          }}
          isBuyer={party === "buyer"}
        />
      </MpContainer>
      <MpFooter>
        <MpContainer>
          <MpFooterCta
            loading={isSubmitting}
            disabled={isSubmitting || form.hasErrors}
            onClick={async () => {
              await submit({
                first_name: form.data.first_name.value,
                last_name: form.data.last_name.value,
                phone_number: form.data.phone_number.value,
                email: form.data.email.value,
                instructions: form.data.instructions.value,
              });
            }}
          >
            {party === "buyer" && t((d) => d.fo.button.label.go_to_extra_services)}
            {party === "seller" && t((d) => d.fo.button.label.go_to_overview)}
          </MpFooterCta>
        </MpContainer>
      </MpFooter>
      <EditContactModal
        {...editModal}
        party={party}
        partyId={partyId}
        address={address}
        contact={contact}
        update={form.set}
      />
      <PhoneNumberModal {...phoneModal} />
    </>
  );
};
