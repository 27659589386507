import { IconChat, useModalState, Modal } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useTranslationContext } from "../../../hooks";
import { Routes } from "../../../utils";
import { trackAndTag } from "../../../utils/eventTracking";
import { useDraftTr } from "../../hooks/useDraftTr";
import { CHAT_HIDDEN_CLASS, ChatButton } from "../ChatButton";
import { MpModalContentHead } from "../Modal";

export const ChatModal: React.FC = () => {
  const helpModal = useModalState();
  const { t } = useTranslationContext();
  const location = useLocation();
  const match = matchPath<{ id: string }>(location.pathname, {
    path: Routes.MARKTPLAATS,
    exact: false,
  });
  const partyId = match?.params?.id as string;
  const draftTr = useDraftTr({ partyId });

  return (
    <>
      <small>
        <button
          onClick={() => {
            if (document.body.classList.contains(CHAT_HIDDEN_CLASS)) {
              trackAndTag({
                event: "FO Interaction",
                type: "Modal open - help",
                data: draftTr.data?.party || "",
                partyId,
              });
              helpModal.open();
              return;
            }
            document.body.classList.add(CHAT_HIDDEN_CLASS);
          }}
          type="button"
          className={cn("bg-transparent flex items-center gap-2")}
        >
          <IconChat /> <span className="show-when-fc-hidden">{t((d) => d.fo.help_modal.button)}</span>
          <span className="show-when-fc-shown">{t((d) => d.fo.help_modal.hide_chat)}</span>
        </button>
      </small>
      <Modal
        theme="primary"
        header={
          <small className="inline-flex items-center gap-2">
            <IconChat />
            {t((d) => d.fo.help_modal.button)}
          </small>
        }
        {...helpModal}
      >
        <MpModalContentHead title={t((d) => d.fo.help_modal.header)} sub={t((d) => d.fo.help_modal.sub_header)} />
        <ChatButton onClose={helpModal.close} />
      </Modal>
    </>
  );
};
