import { Emphasis, useModalState } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React from "react";

import { DriverDetails, IconEnvelope, IconTelephone } from "../../../components";
import { useTranslationContext, useTransportContext } from "../../../hooks";
import { createAccessControl } from "../../../utils";

import { CallModal } from "./CallModal";
import { ChatModal } from "./ChatModal";

const getPerms = createAccessControl({
  pickup: {
    canMakeCall: [3],
    canSeeCallIcon: [2, 3],
    canSendMessage: undefined,
  },
  delivery: {
    canMakeCall: [3, 4],
    canSeeCallIcon: [2, 3, 4],
    canSendMessage: undefined,
  },
  customer: {
    canMakeCall: [3, 4],
    canSeeCallIcon: [2, 3, 4],
    canSendMessage: [2, 3, 4],
  },
});

const responsiveCns = cn("w-4", "h-4", "sm:w-5", "sm:h-5");

const cns = cn(
  "border",
  "border-blue-600",
  "text-blue-600",
  "rounded-full",
  "p-3",
  "flex-shrink-0",
  "disabled:opacity-50"
);

export const AcceptedDriver: React.FC = () => {
  const { t } = useTranslationContext();
  const { tr, acceptedTjal, status, contactType } = useTransportContext();

  const chatModal = useModalState();
  const callModal = useModalState();

  if (!acceptedTjal) return null;

  const { perms } = getPerms(contactType, status);

  const driverPhoneNumber = acceptedTjal.driver_user.phone;
  // NOTE: check against empty-string scenario.
  const hasDriverPhoneNumber = Boolean(driverPhoneNumber);
  const tjalId = getIdFromIri(acceptedTjal["@id"]);
  const customerIri = tr?.customer["@id"];

  return (
    <>
      <div>
        <div className={cn("flex", "justify-between", "flex-wrap")}>
          <DriverDetails driverUser={acceptedTjal.driver_user} />
          <div>
            <div className={cn("flex", "my-2")}>
              {/* NOTE: A driver cannot interact via chat with customers when the job is bundled. */}
              {perms?.canSendMessage && !tr?.transport_job_bundled && (
                <button disabled={!perms.canSendMessage} className={cn(cns)} onClick={chatModal.open}>
                  <IconEnvelope className={responsiveCns} />
                </button>
              )}
              {perms?.canSeeCallIcon && hasDriverPhoneNumber && (
                <button disabled={!perms?.canMakeCall} className={cn(cns, "ml-2")} onClick={callModal.open}>
                  <IconTelephone className={responsiveCns} />
                </button>
              )}
            </div>
          </div>
        </div>
        {perms?.canSeeCallIcon && !perms?.canMakeCall && (
          <div className={cn("mt-2", "text-blue-600")}>
            <Emphasis>{t((d) => d.phone_number_disclaimer)}</Emphasis>
          </div>
        )}
      </div>
      <CallModal {...callModal} driverPhoneNumber={driverPhoneNumber} />
      {tjalId && customerIri && <ChatModal {...chatModal} tjalId={tjalId} customerIri={customerIri} />}
    </>
  );
};
