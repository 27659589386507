import React from "react";
import deliveryImage from "../../assets/illuDelivery.svg";
import { Modal, MpModalContentHead } from "..";
import { useTranslationContext } from "../../../hooks";
import { ModalProps } from "@brenger/react";

export const DeliveryDateModal: React.FC<Pick<ModalProps, "close" | "isOpen">> = (props) => {
  const { t } = useTranslationContext();
  return (
    <Modal
      header={t((d) => d.fo.delivery_date_modal.title)}
      theme="head-primary-light"
      headerImage={<img className="mx-auto" width="239" height="122" src={deliveryImage} />}
      {...props}
    >
      <MpModalContentHead
        title={t((d) => d.fo.delivery_date_modal.header)}
        sub={t((d) => d.fo.delivery_date_modal.sub_header)}
      />
      <div className="mb-8 pt-2">
        <b className="block mb-1">{t((d) => d.fo.delivery_date_modal.step_1)}</b>
        {t((d) => d.fo.delivery_date_modal.step_1_text)}
      </div>
      <div className="mb-8 pt-2">
        <b className="block mb-1">{t((d) => d.fo.delivery_date_modal.step_2)}</b>
        {t((d) => d.fo.delivery_date_modal.step_2_text)}
      </div>
      <div className="mb-8 pt-2">
        <b className="block mb-1">{t((d) => d.fo.delivery_date_modal.step_3)}</b>
        {t((d) => d.fo.delivery_date_modal.step_3_text)}
      </div>
    </Modal>
  );
};
