import { CoreViolation } from "@brenger/api-client";
import { Button, IconLoader, Message, Modal, UseModalState } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useTranslationContext, useTransportContext } from "../../../hooks";
import { CacheKey, coreClient } from "../../../utils";

export const CancelModalBusiness: React.FC<UseModalState> = (modalProps) => {
  const { t } = useTranslationContext();
  const { trId, refresh } = useTransportContext();

  const onCancelSuccess = (): void => {
    refresh();
    modalProps.close?.();
  };

  const costs = useQuery(
    [CacheKey.RETRIEVE_CANCELATION_COSTS, trId],
    () => coreClient.transportRequests.retrieveCancellationCosts({ id: trId || "" }),
    {
      enabled: !!trId && modalProps.isOpen,
    }
  );

  const isFree = costs.data?.current_charge === 0;

  const currentCosts = (costs.data?.deadlines || []).find(
    (deadline) => deadline[Object.keys(deadline)[0]] === costs.data?.current_percentage
  );

  /* @TODO FORMAT DEADLINE AS DATE TIME CORRECTLY! */
  const deadline = Object.keys(currentCosts || {})[0];

  const cancel = useMutation(coreClient.transportRequests.cancel, { onSuccess: onCancelSuccess });

  if (!costs.data) {
    return (
      <Modal {...modalProps}>
        <div className={cn("flex", "justify-center", "items-center", "h-48")}>
          <IconLoader className={cn("w-8", "h-8")} />
        </div>
      </Modal>
    );
  }

  if (costs.error) {
    return (
      <Modal {...modalProps}>
        <Message type="error">{(costs.error as CoreViolation).message}</Message>
      </Modal>
    );
  }

  return (
    <Modal {...modalProps}>
      <h3 className={cn("mb-4")}>{t((d) => d.cancel_transport_title)}</h3>
      {isFree && <div>{t((d, template) => template(d.cancel_transport_free_desc, { deadline }))}</div>}
      {!isFree && (
        <div>
          {t((d, template) =>
            template(d.cancel_transport_percentage_penalty_desc, {
              deadline,
              percentage: costs.data.current_percentage || "??",
            })
          )}
        </div>
      )}
      {!!cancel.error && (
        <Message type="error" className={cn("mt-2")}>
          {(cancel.error as CoreViolation).message}
        </Message>
      )}
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4", "mt-8")}>
        <Button buttonType="primary-outline" disabled={cancel.isLoading} onClick={modalProps.close}>
          {t((d) => d.no)}
        </Button>
        <Button loading={cancel.isLoading} buttonType="warning" onClick={() => cancel.mutate({ id: trId || "" })}>
          {t((d) => d.agree)}
        </Button>
      </div>
    </Modal>
  );
};
