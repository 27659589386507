import { Spacer, useModalState, Modal } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { IconBrengerLogo, IconPersonCircle, IconThreeDots, LanguageSelect } from ".";
import { useAuth, useTranslationContext } from "../hooks";
import { Routes, getCustomerAppUrl, userDataClient } from "../utils";

const responsiveCns = cn("h-8", "w-8", "sm:h-10", "sm:w-10");

const imgCns = cn(responsiveCns, "rounded-full", "object-cover", "border-solid", "border-2", "border-gray-400");

const linkCns = cn("text-blue-600", "underline");

export const Header: React.FC = () => {
  const menuModal = useModalState();
  const auth = useAuth();
  const { t, i18n } = useTranslationContext();
  const [isImgValid, setIsImgValid] = React.useState(true);

  const createTransportRequestLink = auth.businessSlug
    ? `${getCustomerAppUrl(i18n.locale)}/customer/business/${auth.businessSlug}/transport_request`
    : `${getCustomerAppUrl(i18n.locale)}/customer/transport_request`;

  const dashboardLink = auth.businessSlug
    ? `${getCustomerAppUrl(i18n.locale)}/customer/business/${auth.businessSlug}/dashboard`
    : null;

  return (
    <>
      <header className={cn("py-3", "px-4")}>
        <div className={cn("flex", "justify-between", "items-center", "h-10", "sm:h-12")}>
          <div className={cn("h-10", "sm:h-12", "py-1")}>
            <IconBrengerLogo
              className={cn("py-0", "sm:py-1", "fill-current", "text-blue-600", "object-scale-down", "max-w-full")}
            />
          </div>
          <div className={cn("flex", "items-center")}>
            <LanguageSelect />
            <Spacer w={4} />
            {auth.user ? (
              <button onClick={menuModal.open}>
                {isImgValid ? (
                  <img
                    src={userDataClient.getProfileImageUrl(getIdFromIri(auth.user?.["@id"]) || "")}
                    // This is for gracefully handling users that have NOT uploaded an image yet .
                    onError={() => setIsImgValid(false)}
                    className={imgCns}
                  />
                ) : (
                  // Show a "three dot" context menu when the user has not uploaded an image yet.
                  <div className={cn(imgCns, "flex", "justify-center", "items-center")}>
                    <IconThreeDots />
                  </div>
                )}
              </button>
            ) : (
              // We direct users to the account url so they can login there
              // The document.referrer will return them to the tracking app.
              <Link to={Routes.LOGIN}>
                <IconPersonCircle className={cn(responsiveCns, "text-gray-400")} />
              </Link>
            )}
          </div>
        </div>
      </header>
      <Modal {...menuModal}>
        <div className={cn("grid", "grid-cols-1", "gap-6", "p-4")}>
          <h3>
            {t((d) => d.hello)} {auth.account?.name}!
          </h3>
          <a href={createTransportRequestLink}>
            <div className={linkCns}>{t((d) => d.new_transport)}</div>
          </a>
          <Link to={Routes.TRANSPORT_LIST} onClick={menuModal.close}>
            <div className={linkCns}>{t((d) => d.view_transports)}</div>
          </Link>
          {dashboardLink && (
            <a href={dashboardLink}>
              <div className={linkCns}>{t((d) => d.your_dashboard)}</div>
            </a>
          )}
          <a href={`${getCustomerAppUrl(i18n.locale)}/customer/invoices`}>
            <div className={linkCns}>{t((d) => d.invoices)}</div>
          </a>
          <Link to={Routes.LOGOUT} onClick={menuModal.close}>
            <div className={linkCns}>{t((d) => d.logout)}</div>
          </Link>
        </div>
      </Modal>
    </>
  );
};
