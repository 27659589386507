import { Button, useModalState } from "@brenger/react";
import React from "react";
import { useTranslationContext } from "../../../hooks";
import { Modal, MpModalContentHead } from "..";
import { CheckmarkList } from "../../../components";

interface Props {
  isNotFeasible: boolean;
  reasons: string[];
}

export const NotFeasibleModal: React.FC<Props> = (props) => {
  const { t } = useTranslationContext();
  const modal = useModalState();

  React.useEffect(() => {
    if (props.isNotFeasible) {
      modal.open();
    }
  }, [props.isNotFeasible]);

  return (
    <Modal isOpen={modal.isOpen} theme="primary">
      <MpModalContentHead title={t((d) => d.fo.not_feasible_modal.header)} />
      <CheckmarkList
        items={[t((d) => d.fo.not_feasible_modal.checkmark_1), t((d) => d.fo.not_feasible_modal.checkmark_2)]}
      />
      <Button buttonType="secondary" className="mt-8 w-full lg:w-auto lg:px-12" onClick={modal.close}>
        {t((d) => d.fo.not_feasible_modal.button)}
      </Button>
    </Modal>
  );
};
