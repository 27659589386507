import { CoreViolation, DateTimePeriodParams } from "@brenger/api-client";
import { Button, Message, Modal, Spacer, Strong, useModalState } from "@brenger/react";
import { getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import isSameDay from "date-fns/isSameDay";
import React from "react";
import { useMutation, useQuery } from "react-query";

import { IconCheckCircle, PageContainer } from "../../components";
import { useFormatDate, useTranslationContext, useTransportContext } from "../../hooks";
import { CacheKey, coreClient, priceClient } from "../../utils";

export const UpdateDTP: React.FC = () => {
  const { t } = useTranslationContext();
  const [newDtp, setNewDtp] = React.useState<DateTimePeriodParams>();
  const confirmUpdateModal = useModalState();

  // @TODO pick better formatter and add to @brenger/utils!
  const formatFullDate = useFormatDate("date-full");

  const ctx = useTransportContext();
  const { pickup, delivery, trId, refresh } = ctx;

  // Set-up all the pre conditions
  const isBusinessOrder = ctx.tr?.source_flow === "Business";
  const datesChangeable = Boolean(ctx.tr?.dates_changeable);
  const isRegularTransport = ctx.tr?.transport_job_bundled === false;
  const hasCorrectStatus = ctx.status && ctx.status <= 2;

  // Combine conditions into a master const
  const areDatesChangeable = isBusinessOrder && datesChangeable && isRegularTransport && hasCorrectStatus;

  const dates = useQuery(
    [CacheKey.RETRIEVE_BUSINESS_DATES_LIST, trId],
    () => priceClient.quotes.retrieveDateListForBusinessTransportRequest({ id: trId || "" }),
    {
      enabled: areDatesChangeable === true && trId !== undefined,
    }
  );

  const updateDtpsSuccess = (): void => {
    // Free up local state.
    setNewDtp(undefined);
    // Refresh the over-arching TR.
    refresh();
    // close the modal
    confirmUpdateModal.close();
  };

  const updatePickupDtp = useMutation(coreClient.dateTimePeriods.update);
  const updateDeliveryDtp = useMutation(coreClient.dateTimePeriods.update);
  React.useEffect(() => {
    if (!!updatePickupDtp.data || !!updateDeliveryDtp.data) {
      updateDtpsSuccess();
    }
  }, [!!updatePickupDtp.data, !!updateDeliveryDtp.data]);

  // Get ref to available DTP for both pickup & delivery so that we can check current day as well as update if needed.
  const firstAvailablePickupDtp = pickup?.available_datetime_periods[0];
  const firstAvailableDeliveryDtp = delivery?.available_datetime_periods[0];

  // Completely hide this section under the following circumstances:
  if (areDatesChangeable === false || dates.data?.length === 0) return null;

  const updateDtpErr =
    (updatePickupDtp.error as CoreViolation | undefined)?.message ||
    (updateDeliveryDtp.error as CoreViolation | undefined)?.message;

  return (
    <>
      <PageContainer className={cn("py-6", "border-b-4")} style={{ borderColor: "#D1E4FD" }}>
        <h6>{t((d) => d.change_pickup_date)}</h6>
        <Spacer h={2} />
        <div>{t((d) => d.change_pickup_date_desc)}</div>
        <Spacer h={4} />
        <div className={cn("grid", "grid-cols-1", "sm:grid-cols-2", "lg:grid-cols-3", "gap-4")}>
          {dates.data?.map((dateTimePeriod, idx) => {
            const isSameDtp =
              firstAvailableDeliveryDtp?.start === undefined
                ? false
                : isSameDay(new Date(firstAvailableDeliveryDtp.start), new Date(dateTimePeriod.start));

            return (
              <button
                disabled={isSameDtp}
                key={idx}
                className={cn("flex", "justify-between", "items-center", "p-4", "border", "rounded", {
                  "border-green-400": isSameDtp,
                  "border-gray-500": !isSameDtp,
                })}
                onClick={() => {
                  setNewDtp(dateTimePeriod);
                  confirmUpdateModal.open();
                }}
              >
                <div className={cn("capitalize")}>{formatFullDate(dateTimePeriod.start)}</div>
                {isSameDtp ? <IconCheckCircle className={cn("w-6", "h-6", "text-green-400")} /> : null}
              </button>
            );
          })}
        </div>
      </PageContainer>
      {/* UPDATE DTP CONFIRMATION MODAL */}
      <Modal {...confirmUpdateModal}>
        <h2>{t((d) => d.confirm_date)}</h2>
        <Spacer h={4} />
        <div>
          {t((d) => d.you_searched_for)}{" "}
          {newDtp ? <Strong className={cn("capitalize")}>{formatFullDate(newDtp.start)}</Strong> : null}
        </div>
        <div>{t((d) => d.change_date_confirm_desc)}</div>
        {updateDtpErr && (
          <Message type="error" className={cn("mt-2")}>
            {updateDtpErr}
          </Message>
        )}
        <Spacer h={4} />
        <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4")}>
          <Button buttonType="primary-outline" onClick={confirmUpdateModal.close}>
            {t((d) => d.cancel)}
          </Button>
          <Button
            loading={updatePickupDtp.isLoading || updateDeliveryDtp.isLoading}
            buttonType="secondary"
            onClick={() => {
              if (newDtp === undefined) return;
              const pickupDtpId = getIdFromIri(firstAvailablePickupDtp?.["@id"]);
              if (pickupDtpId === undefined) return;
              const deliveryDtpId = getIdFromIri(firstAvailableDeliveryDtp?.["@id"]);
              if (deliveryDtpId === undefined) return;
              updatePickupDtp.mutate({ id: pickupDtpId, dateTimePeriod: newDtp });
              updateDeliveryDtp.mutate({ id: deliveryDtpId, dateTimePeriod: newDtp });
            }}
          >
            {t((d) => d.confirm)}
          </Button>
        </div>
      </Modal>
    </>
  );
};
