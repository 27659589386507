import { FoPartyIdParams } from "@brenger/api-client";
import React from "react";
import { useTranslationContext } from "../../../hooks";
import helpImage from "../../assets/illuCarryingHelp.svg";
import { useExtraServiceOptions } from "../../hooks/useExtraServiceOptions";
import { CheckmarkList } from "../../../components/CheckmarkList";
import { Modal, MpModalContentHead } from "../Modal";
import { Price } from "../Price";
import { IconInfoCircle, IconLoader, ModalProps } from "@brenger/react";
import { MpCard } from "../Layout";

export const ServiceModal: React.FC<FoPartyIdParams & Pick<ModalProps, "close" | "isOpen">> = ({
  partyId,
  ...props
}) => {
  const { t } = useTranslationContext();
  const { isLoading, options } = useExtraServiceOptions({ partyId, isEnabled: props.isOpen });
  return (
    <Modal
      header={t((d) => d.fo.service_modal.title)}
      theme="head-primary-light"
      headerImage={<img width="199" height="104" className="mx-auto" src={helpImage} />}
      {...props}
    >
      <MpModalContentHead title={t((d) => d.fo.service_modal.header)} sub={t((d) => d.fo.service_modal.sub_header)} />
      {isLoading && <IconLoader />}
      {!isLoading &&
        options.map((helpOption, i) => {
          const isFree = helpOption.base_price.value === "0.00";
          const hasFloorService = helpOption.floor_level_service !== "not_available";
          return (
            <React.Fragment key={i}>
              {i > 0 && <hr className="border-gray-400 my-8" />}
              <div className="flex justify-between pb-1">
                {helpOption.isStandard && <b className="text-blue-600">{t((d) => d.fo.service.standard)}</b>}
                {!helpOption.isStandard && <b className="text-green-400">{t((d) => d.fo.service.extra_service)}</b>}
                {isFree && !hasFloorService && <div className="text-gray-600">{t((d) => d.fo.price.free)}</div>}
                {((isFree && hasFloorService) || !isFree) && (
                  <div className="text-gray-600">
                    {t((d) => d.fo.price.from)} <Price price={helpOption.base_price.value} />
                  </div>
                )}
              </div>
              <h5 className="pb-2 block">{helpOption.public_name}</h5>
              <CheckmarkList verticalAlign="top" items={helpOption.pros} />
              {helpOption.floor_level_service !== "not_available" && (
                <small className="block mt-4 text-gray-600">*{t((d) => d.fo.services.price_depends_floor)}</small>
              )}
            </React.Fragment>
          );
        })}
      {!isLoading && options.some((option) => option.floor_level_service !== "not_available") && (
        <>
          <hr className="border-gray-400 my-8" />
          <MpCard theme="primary-light" className="pt-4">
            <div className="flex items-center gap-4 flex-wrap">
              <IconInfoCircle />
              <div
                className="w-[calc(100%-3rem)]"
                dangerouslySetInnerHTML={{ __html: t((d) => d.fo.services.available_dependency) }}
              />
            </div>
          </MpCard>
        </>
      )}
    </Modal>
  );
};
