import { Button, IconLoader, Modal, Spacer, Strong, useModalState } from "@brenger/react";
import cn from "classnames";
import React from "react";

import { Card, Page, PageContainer } from "../../components";
import { TransportListProvider, useTranslationContext, useTransportListContext } from "../../hooks";

import { CardForBusiness } from "./CardForBusiness";
import { CardForCustomer } from "./CardForCustomer";
import { Filters } from "./Filters";

const TransportListContainer: React.FC = () => {
  const { t } = useTranslationContext();
  const ctx = useTransportListContext();
  const filtersModal = useModalState();

  React.useEffect(() => {
    // When the list is loading (bc user clicked a filter or new page) scroll back to top.
    if (ctx.isLoading) window.scrollTo({ top: 0 });
  }, [ctx.isLoading]);

  return (
    <Page>
      <PageContainer>
        <div className={cn("grid", "grid-cols-3", "gap-4", "sm:gap-8")}>
          {/* GRID TILE 1 - MOBILE FILTERS CTA */}
          <div className={cn("sm:hidden", "col-span-3")}>
            <Button className={cn("w-full")} onClick={filtersModal.open}>
              {t((d) => d.filters)}
            </Button>
          </div>
          {/* GRID TILE 2 - CARDS */}
          <div className={cn("sm:col-span-2", "col-span-3")}>
            <div className={cn("flex", "items-center")}>
              <div>
                <Strong>{t((d) => d.your_transports)}</Strong>
              </div>
              {ctx.isLoading && <IconLoader className={cn("ml-3")} />}
            </div>
            <div className={cn("grid", "grid-cols-1", "gap-4", "mt-2", "mb-4")}>
              {/* Sprinkle in a few pulsating, placeholder cards as the TR list loads to make user happy. */}
              {ctx.paginationControls?.totalItems === undefined && (
                <>
                  <Card
                    header={<div className={cn("h-8")} />}
                    body={<div className={cn("h-16")} />}
                    footer={<div className={cn("h-4")} />}
                  />
                  <Card
                    header={<div className={cn("h-8")} />}
                    body={<div className={cn("h-16")} />}
                    footer={<div className={cn("h-4")} />}
                  />
                  <Card
                    header={<div className={cn("h-8")} />}
                    body={<div className={cn("h-16")} />}
                    footer={<div className={cn("h-4")} />}
                  />
                </>
              )}
              {ctx.paginationControls?.totalItems === 0 && <h4>{t((d) => d.no_results)}</h4>}
              {ctx.trList.map((tr) => {
                return tr.source_flow === "Business" ? (
                  <CardForBusiness key={tr.short_id} tr={tr} />
                ) : (
                  <CardForCustomer key={tr.short_id} tr={tr} />
                );
              })}
            </div>
          </div>
          {/* GRID TILE 3 - DESKTOP STICKY FILTER */}
          <div className={cn("sm:col-span-1", "hidden", "sm:block")}>
            <div className={cn("top-0", "sticky")}>
              <Filters />
            </div>
          </div>
          {/* GRID TILE 4 - PAGINATION */}
          <div
            className={cn("sm:col-span-2", "col-span-3", "flex", "justify-center", "items-center", {
              "mb-8": ctx.paginationControls?.isPaginated,
            })}
          >
            {ctx.paginationControls?.previousPageNumber &&
              ctx.paginationControls.firstPageNumber < ctx.paginationControls.previousPageNumber && (
                <>
                  <Button
                    size="sm"
                    buttonType="primary-outline"
                    className={cn("mr-2")}
                    onClick={() => {
                      ctx.filters?.set({ page: ctx.paginationControls?.firstPageNumber });
                    }}
                  >
                    {ctx.paginationControls.firstPageNumber}
                  </Button>
                  <div className={cn("mr-2")}>&hellip;</div>
                </>
              )}
            {ctx.paginationControls?.hasPreviousPage && (
              <Button
                size="sm"
                buttonType="primary-outline"
                className={cn("mr-2")}
                onClick={() => {
                  ctx.filters?.set({ page: ctx.paginationControls?.previousPageNumber });
                }}
              >
                {ctx.paginationControls?.previousPageNumber}
              </Button>
            )}
            {ctx.paginationControls?.isPaginated && (
              <Button size="sm">{ctx.paginationControls?.currentPageNumber}</Button>
            )}
            {ctx.paginationControls?.hasNextPage && (
              <Button
                size="sm"
                buttonType="primary-outline"
                className={cn("ml-2")}
                onClick={() => {
                  ctx.filters?.set({ page: ctx.paginationControls?.nextPageNumber });
                }}
              >
                {ctx.paginationControls?.nextPageNumber}
              </Button>
            )}
            {ctx.paginationControls?.nextPageNumber &&
              ctx.paginationControls.lastPageNumber > ctx.paginationControls.nextPageNumber && (
                <>
                  <div className={cn("ml-2")}>&hellip;</div>
                  <Button
                    size="sm"
                    buttonType="primary-outline"
                    className={cn("ml-2")}
                    onClick={() => {
                      ctx.filters?.set({ page: ctx.paginationControls?.lastPageNumber });
                    }}
                  >
                    {ctx.paginationControls.lastPageNumber}
                  </Button>
                </>
              )}
          </div>
        </div>
      </PageContainer>
      {/* FILTER MODAL FOR MOBILE VIEWPORTS */}
      <Modal {...filtersModal}>
        <Filters />
        <Spacer h={4} />
        <Button className={cn("w-full")} onClick={filtersModal.close}>
          {t((d) => d.submit)}
        </Button>
      </Modal>
    </Page>
  );
};

const TransportList: React.FC = () => {
  return (
    <TransportListProvider>
      <TransportListContainer />
    </TransportListProvider>
  );
};

export default TransportList;
