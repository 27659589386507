import React from "react";
import { useTranslationContext } from "../../../hooks";
import { Modal, MpModalContentHead, VanSpeechBalloon } from "../";
import { ModalProps } from "@brenger/react";

export const PhoneNumberModal: React.FC<Pick<ModalProps, "close" | "isOpen">> = (props) => {
  const { t } = useTranslationContext();
  return (
    <Modal
      header={t((d) => d.fo.phone_number_modal.title)}
      theme="head-primary-light"
      headerImage={
        <div className="mx-auto">
          <VanSpeechBalloon text={t((d) => d.fo.phone_number_modal.image_text)} />
        </div>
      }
      {...props}
    >
      <MpModalContentHead
        title={t((d) => d.fo.phone_number_modal.head)}
        sub={t((d) => d.fo.phone_number_modal.sub_head)}
      />
      <div className="mb-8 pt-2">
        <b className="block mb-1">{t((d) => d.fo.phone_number_modal.step_1)}</b>
        {t((d) => d.fo.phone_number_modal.step_1_text)}
      </div>
      <div className="mb-8 pt-2">
        <b className="block mb-1">{t((d) => d.fo.phone_number_modal.step_2)}</b>
        {t((d) => d.fo.phone_number_modal.step_2_text)}
      </div>
    </Modal>
  );
};
