import { Button, useModalState } from "@brenger/react";
import cn from "classnames";
import React from "react";

import { PageContainer } from "../../../components";
import { useTranslationContext, useTransportContext } from "../../../hooks";
import { createAccessControl } from "../../../utils";

import { useHistory, useLocation } from "react-router-dom";
import { CancelModalBusiness } from "./CancelModalBusiness";
import { CancelModalRegular } from "./CancelModalRegular";
import { CantCancelModalRegular } from "./CantCancelModalRegular";

export const TR_ACTION_PARAM = "tr_action";
export const TR_ACTION_CANCEL = "cancel";

const getPerms = createAccessControl({
  pickup: {
    canCancel: undefined,
  },
  delivery: {
    canCancel: undefined,
  },
  customer: {
    canCancel: [1, 2, 3],
  },
});

export const CancelCTA: React.FC = () => {
  const cancelRegular = useModalState();
  const cantCancelRegular = useModalState();
  const cancelBusiness = useModalState();
  const history = useHistory();
  const location = useLocation();

  const { t } = useTranslationContext();
  const { tr, status, contactType } = useTransportContext();

  const isBusiness = tr?.source_flow === "Business";

  React.useEffect(() => {
    if (!tr) return;

    const searchParams = new URLSearchParams(location.search);
    // See if we are dealing with a cancel action, show the correct modal
    if (searchParams.get(TR_ACTION_PARAM) === TR_ACTION_CANCEL) {
      if (tr.cancelable) cancelRegular.open();
      if (!tr.cancelable) cantCancelRegular.open();
      history.replace(location.pathname);
    }
  }, [location.search, tr]);

  if (contactType === undefined || status === undefined) return null;

  const { hasSomePerms } = getPerms(contactType, status);

  if (!hasSomePerms) return null;

  return (
    <>
      {tr?.cancelable && (
        <PageContainer className={cn("py-6")}>
          <Button
            buttonType="warning-outline"
            onClick={() => {
              if (isBusiness) {
                cancelBusiness.open();
              } else {
                cancelRegular.open();
              }
            }}
          >
            {t((d) => d.cancel_transport_cta)}
          </Button>
        </PageContainer>
      )}
      <CancelModalRegular {...cancelRegular} />
      <CantCancelModalRegular {...cantCancelRegular} />
      <CancelModalBusiness {...cancelBusiness} />
    </>
  );
};
