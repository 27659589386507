import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { MpRouteComponentProps, MpRoutes } from "./routes";
import { Flow } from "./Flow";
import { ThankYou } from "./ThankYou";
import { PaymentCanceled } from "./PaymentCanceled";
import { Cancel } from "./Cancel";
import { LOCALE_SEARCH_PARAM, getSearchParam, storeLocale } from "@brenger/utils";
import { IsCancelledPage } from "./Pages/Cancel/IsCancelledPage";
import { CHAT_HIDDEN_CLASS } from "./components/ChatButton";

const locale = getSearchParam(LOCALE_SEARCH_PARAM);

export const Marktplaats: React.FC<MpRouteComponentProps> = () => {
  /**
   * We persist locale in local storage if it is set by param
   * This way we serve a consistent locale setting when the user refreshes or we he/she comes back from payment or browser history
   * NOTE: We remove this storage when we reach complete or when a tracking page is hit, because we want to fallback to normal behaviour
   */
  React.useEffect(() => {
    if (locale) storeLocale(locale);
    document.body.classList.add(CHAT_HIDDEN_CLASS);
    return () => {
      // Cleanup the chat hidden class so chat is visible when redirected to tracking pages
      document.body.classList.remove(CHAT_HIDDEN_CLASS);
    };
  }, []);

  return (
    <div data-clarity-unmask="true">
      <Switch>
        <Route path={MpRoutes.THANK_YOU} component={ThankYou} />
        <Route path={MpRoutes.FLOWS} component={Flow} />
        <Route path={MpRoutes.CANCEL} component={Cancel} />
        <Route path={MpRoutes.IS_CANCELLED} component={IsCancelledPage} />
        <Route path={MpRoutes.PAYMENT_CANCELED} component={PaymentCanceled} />
        <Redirect to={MpRoutes.FLOWS} />
      </Switch>
    </div>
  );
};
