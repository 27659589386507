import { FoAddress, FoBaseParams, FoContact } from "@brenger/api-client";
import { Button, ExtraSmall, IconInfoCircle, InputText, ModalProps, useForm } from "@brenger/react";
import cn from "classnames";
import React from "react";
import { useTranslationContext } from "../../../hooks";
import { trackAndTag } from "../../../utils/eventTracking";
import { MpCard } from "../Layout";
import { Modal, MpModalContentHead } from "../Modal";

interface Props extends FoBaseParams, Pick<ModalProps, "close" | "isOpen"> {
  contact: FoContact;
  address: FoAddress;
  update(c: Pick<FoContact, "first_name" | "last_name" | "email">): void;
}

export const EditContactModal: React.FC<Props> = ({ party, partyId, contact, address, update, ...modalProps }) => {
  const { t } = useTranslationContext();
  const form = useForm({
    initialState: {
      first_name: contact.first_name,
      last_name: contact.last_name,
      email: contact.email,
    },
    validators: {
      email: (v) => {
        if (!v) return t((d) => d.fo.contact_page.fields.required);
        if (!/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v)) {
          return t((d) => d.fo.contact_page.fields.email.error);
        }
        return false;
      },
      first_name: (v) => (!v ? t((d) => d.fo.contact_page.fields.required) : false),
      last_name: (v) => (!v ? t((d) => d.fo.contact_page.fields.required) : false),
    },
  });

  const houseNumber = [address.house_number, address.house_number_addition].filter(Boolean).join("");
  const addrCls = `!bg-gray-100 text-gray-600 px-4 py-3 text-lg border-gray-400`;
  return (
    <Modal
      header="Wijzig"
      footer={
        <Button
          disabled={form.hasErrors}
          className={"w-full"}
          onClick={() => {
            update({
              first_name: form.data.first_name.value,
              last_name: form.data.last_name.value,
              email: form.data.email.value,
            });
            modalProps.close?.();
          }}
        >
          {t((d) => d.fo.contact_modal.button)}
        </Button>
      }
      {...modalProps}
    >
      <MpModalContentHead title={t((d) => d.fo.contact_modal.title)} sub={t((d) => d.fo.contact_modal.sub_title)} />

      <div className="mb-2">
        <InputText
          placeholder={t((d) => d.fo.contact_page.fields.first_name.placeholder)}
          className={cn("w-full", "px-4 py-3", "text-lg", "border-gray-400", {
            "border-red-300": !!form.data.first_name.error && form.data.first_name.isDirty,
          })}
          onChange={(v) => {
            form.set({ first_name: v });
            trackAndTag({ event: "FO Interaction", type: `${party} update details`, data: "first_name", partyId });
          }}
          value={form.data.first_name.value}
        />
        {form.data.first_name.error && form.data.first_name.isDirty && (
          <div className="text-red-600">
            <ExtraSmall>{form.data.first_name.error}</ExtraSmall>
          </div>
        )}
      </div>
      <div className="mb-2">
        <InputText
          placeholder={t((d) => d.fo.contact_page.fields.last_name.placeholder)}
          className={cn("w-full", "px-4 py-3", "text-lg", "border-gray-400", {
            "border-red-300": !!form.data.last_name.error && form.data.last_name.isDirty,
          })}
          onChange={(v) => {
            form.set({ last_name: v });
            trackAndTag({ event: "FO Interaction", type: `${party} update details`, data: "last_name", partyId });
          }}
          value={form.data.last_name.value}
        />
        {form.data.last_name.error && form.data.last_name.isDirty && (
          <div className="text-red-600">
            <ExtraSmall>{form.data.last_name.error}</ExtraSmall>
          </div>
        )}
      </div>
      <div className="mb-2">
        <InputText
          placeholder={t((d) => d.fo.contact_page.fields.email.placeholder)}
          className={cn("w-full", "px-4 py-3", "text-lg", "border-gray-400", {
            "border-red-300": !!form.data.email.error && form.data.email.isDirty,
          })}
          onChange={(v) => {
            form.set({ email: v });
            trackAndTag({ event: "FO Interaction", type: `${party} update details`, data: "email", partyId });
          }}
          value={form.data.email.value}
        />
        {form.data.email.error && form.data.email.isDirty && (
          <div className="text-red-600">
            <ExtraSmall>{form.data.email.error}</ExtraSmall>
          </div>
        )}
      </div>
      <div className="flex mt-6 mb-2">
        <InputText className={`w-2/3 ${addrCls}`} disabled value={address.postal_code || ""} />
        <InputText
          style={{ width: "calc(33% - .5rem)" }}
          className={`ml-auto ${addrCls}`}
          disabled
          value={houseNumber}
        />
      </div>
      <InputText className={`mb-2 w-full ${addrCls}`} disabled value={address.street_name} />
      <InputText className={`mb-6 w-full ${addrCls}`} disabled value={address.locality} />
      <MpCard theme="primary-light" className="flex items-center gap-4 pt-4">
        <IconInfoCircle className="shrink-0" />
        <div dangerouslySetInnerHTML={{ __html: t((d) => d.fo.contact_modal.explain_address_not_changable) }} />
      </MpCard>
    </Modal>
  );
};
