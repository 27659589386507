import { FoEditableItemFields } from "@brenger/api-client";
import { Button, InputNumber, useForm, ModalProps } from "@brenger/react";
import React from "react";
import { useTranslationContext } from "../../../hooks";
import { Modal, MpModalContentHead } from "..";

interface Props extends Pick<ModalProps, "close" | "isOpen"> {
  dims: Omit<FoEditableItemFields, "count" | "title">;
  update(dims: Partial<FoEditableItemFields>): void;
}

export const EditItemModal: React.FC<Props> = (props) => {
  const { t } = useTranslationContext();
  const form = useForm({
    initialState: {
      length: 1,
      width: 1,
      height: 1,
      ...props.dims,
    },
    validators: {
      length: (v) => {
        if (!v) return "Length must be more than 0";
        return false;
      },
      width: (v) => {
        if (!v) return "Length must be more than 0";
        return false;
      },
      height: (v) => {
        if (!v) return "Length must be more than 0";
        return false;
      },
    },
  });
  return (
    <Modal header={t((d) => d.fo.item_edit_modal.title)} {...props}>
      <MpModalContentHead title={t((d) => d.fo.item_edit_modal.dimensions)} />
      <div className="flex gap-2 mt-2">
        <label className="w-full relative">
          <InputNumber
            className="w-full pr-6 no-spinner"
            placeholder="0"
            onChange={(length) => form.set({ length: Number(length) })}
            value={form.data.length.value}
          />
          <span className="absolute" style={{ top: "0.4rem", right: "0.8rem" }}>
            cm
          </span>
        </label>
        <label className="w-full relative">
          <InputNumber
            className="w-full pr-6 no-spinner"
            placeholder="0"
            onChange={(width) => form.set({ width: Number(width) })}
            value={form.data.width.value}
          />
          <span className="absolute" style={{ top: "0.4rem", right: "0.8rem" }}>
            cm
          </span>
        </label>
        <label className="w-full relative">
          <InputNumber
            className="w-full pr-6 no-spinner"
            placeholder="0"
            onChange={(height) => form.set({ height: Number(height) })}
            value={form.data.height.value}
          />
          <span className="absolute" style={{ top: "0.4rem", right: "0.8rem" }}>
            cm
          </span>
        </label>
      </div>
      <Button
        className="mt-8 w-full"
        disabled={form.hasErrors}
        onClick={() => {
          props.update({
            length: form.data.length.value,
            width: form.data.width.value,
            height: form.data.height.value,
          });
          props.close?.();
        }}
      >
        {t((d) => d.fo.item_edit_modal.button)}
      </Button>
    </Modal>
  );
};
