import {
  CarryingHelp,
  CarryingHelpOptions,
  Contact,
  CoreViolation,
  TransportRequest,
  TransportRequestOpenForUpsells,
} from "@brenger/api-client";
import {
  Button,
  IconArrowLeft,
  InputCheckbox,
  InputText,
  Label,
  Message,
  Select,
  Spacer,
  UseForm,
  useForm,
  useModalState,
} from "@brenger/react";
import { TextScanLangs, getIdFromIri } from "@brenger/utils";
import cn from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { Link, Redirect, useHistory } from "react-router-dom";

import { IconCheck, PageContainer } from "../../components";
import { useTranslationContext, useTransportContext, useTransportParams } from "../../hooks";
import {
  coreClient,
  createAccessControl,
  getDeliveryFromTr,
  getPickupFromTr,
  getQuoteParamsForTr,
  priceClient,
} from "../../utils";
import { getSuspicions } from "../../utils/textScanning";
import { UpdateInstructionModal } from "./UpdateInstructionModal";
import { UpdateStopDetailsConfirmModal } from "./UpdateStopDetailsConfirmModal";

// NOTE: these are special kinds of carrying help. When selected, they prevent
// the customer from editing other fields (ie, elevator or floor_level) as editing those
// would no longer be feasible.
const carryingHelpWithEquipment: CarryingHelp[] = [
  "equipment_tailgate",
  "equipment_tailgate_pallet_jack",
  "equipment_tailgate_extra_driver",
  "equipment_provided_by_customer",
];

const carryingHelpSansEquipment: CarryingHelp[] = ["needed", "extra_driver"];

const getPerms = createAccessControl({
  pickup: {
    canAddUpsells: undefined,
    canUpdatePickupContact: [1, 2],
    canUpdateDeliveryContact: undefined,
  },
  delivery: {
    canAddUpsells: undefined,
    canUpdatePickupContact: undefined,
    canUpdateDeliveryContact: [1, 2, 3],
  },
  customer: {
    canAddUpsells: [1, 2, 3, 4],
    canUpdatePickupContact: [1, 2],
    canUpdateDeliveryContact: [1, 2, 3],
  },
});

interface UpsellProps {
  form: UseForm.Form<{
    // NOTE: when a field is undefined, it signals that it IS NOT editable.
    elevator: boolean | undefined;
    carrying_help: CarryingHelp | undefined;
    floor_level: number | undefined;
  }>;
}

const Upsell: React.FC<UpsellProps> = ({ form }) => {
  const { openForUpsells } = useTransportContext();
  const { t } = useTranslationContext();
  const floorLevel = form.data.floor_level.value;
  const canUpdateFloorLevel = floorLevel !== undefined;

  const carryingHelp = form.data.carrying_help.value;
  const canUpdateCarryingHelp = carryingHelp !== undefined;

  const elevator = form.data.elevator.value;
  const canUpdateElevator = elevator !== undefined;

  const canUpdateSomeService = canUpdateFloorLevel || canUpdateCarryingHelp || canUpdateElevator;

  // Show a small message indicating that services cannot be added to this stop.
  // This is to add meaningful content when an otherwise empty section would be rendered.
  if (!canUpdateSomeService) {
    return <div>{t((d) => d.cannot_add_services)}</div>;
  }

  const hasEquipment = carryingHelp ? carryingHelpWithEquipment.includes(carryingHelp) : false;

  // There is a mismatch between the field names in pricing and T&T so this mapping is needed.
  type CarryingHelpOptionMap = {
    [key in CarryingHelp]?: string;
  };

  const carryingHelpOptionsMap: CarryingHelpOptionMap = {
    needed: "noHelpAvailable",
    extra_driver: "secondDriverAvailable",
    equipment_tailgate: "tailgateAvailable",
    equipment_tailgate_pallet_jack: "tailgatePalletTruckAvailable",
    equipment_tailgate_extra_driver: "tailgateSecondDriverAvailable",
  };

  // Reduce the available options based on the flow config.
  const carryingHelpOptions = [...carryingHelpSansEquipment, ...carryingHelpWithEquipment].filter((ch) => {
    // Only make sense to present this option if it has equipment is selected
    if (hasEquipment && ch === "equipment_provided_by_customer") {
      return true;
    }

    return openForUpsells?.carrying_help?.options?.[carryingHelpOptionsMap[ch] as keyof CarryingHelpOptions];
  });

  return (
    <div>
      <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4")}>
        {canUpdateFloorLevel && (
          <Label text={t((d) => d.floors)}>
            <Select
              disabled={hasEquipment}
              value={floorLevel}
              onChange={(floor_level) => {
                form.set({ floor_level: parseInt(floor_level, 10) });
              }}
            >
              {[-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((fl) => {
                return (
                  <option key={fl} value={fl}>
                    {fl}
                  </option>
                );
              })}
            </Select>
          </Label>
        )}
        {canUpdateCarryingHelp && (
          <Label text={t((d) => d.carrying_help_title)}>
            <Select
              value={carryingHelp}
              onChange={(val: unknown) => {
                const ch = val as CarryingHelp;
                const chHasEquipment = carryingHelpWithEquipment.includes(ch);
                form.set({
                  carrying_help: val as CarryingHelp,
                  // NOTE: floor_level and elevator must be specifically set when customer picks a type of carrying help that involves equipment.
                  floor_level: chHasEquipment ? 0 : floorLevel,
                  elevator: chHasEquipment ? false : form.data.elevator.value,
                });
              }}
            >
              {/* NOTE: take into account book a van jobs */}
              {carryingHelpOptions.map((ch) => {
                return (
                  <option key={ch} value={ch}>
                    {t((d) => d.carrying_help[ch])}
                  </option>
                );
              })}
            </Select>
          </Label>
        )}
      </div>
      <div className={cn("mt-4")}>
        {/* This is outside of the grid so the checkbox will always be in the end */}
        {canUpdateElevator && (
          <Label position="right" text={t((d) => d.elevator_desc)}>
            <InputCheckbox
              disabled={hasEquipment}
              checked={form.data.elevator.value}
              onChange={() => {
                form.set({ elevator: !form.data.elevator.value });
              }}
            />
          </Label>
        )}
      </div>
    </div>
  );
};

interface InstructionProps {
  form: UseForm.Form<{
    instructions?: string;
  }>;
}

const Instructions: React.FC<InstructionProps> = ({ form }) => {
  const { openForUpsells } = useTransportContext();

  const { t } = useTranslationContext();
  if (!openForUpsells?.instructions?.editable) {
    return null;
  }
  return (
    <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4")}>
      <Label text={t((d) => d.instructions)} error={form.getError("instructions")} isRequired>
        <InputText onChange={(instructions) => form.set({ instructions })} value={form.data.instructions?.value} />
      </Label>
    </div>
  );
};

interface ContactProps {
  form: UseForm.Form<{
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  }>;
}

const Contact: React.FC<ContactProps> = ({ form }) => {
  const { t } = useTranslationContext();

  return (
    <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4")}>
      <Label text={t((d) => d.first_name)} error={form.getError("first_name")} isRequired>
        <InputText onChange={(first_name) => form.set({ first_name })} value={form.data.first_name.value} />
      </Label>
      <Label text={t((d) => d.last_name)} error={form.getError("last_name")} isRequired>
        <InputText onChange={(last_name) => form.set({ last_name })} value={form.data.last_name.value} />
      </Label>
      <Label text="E-mail" error={form.getError("email")} isRequired>
        <InputText
          placeholder="hello@brenger.nl"
          onChange={(email) => form.set({ email })}
          value={form.data.email.value}
        />
      </Label>
      <Label
        isRequired
        text={
          <span className={cn("flex", "w-full", "justify-between")}>
            <span>{t((d) => d.phone_number)}</span>
            {form.getError("phone") && <span className={cn("text-gray-500")}>e.g. +31 xx xxxxxxx</span>}
          </span>
        }
        error={form.getError("phone")}
      >
        <InputText
          placeholder="+31 xx xxxxxxx"
          onChange={(phone) => form.set({ phone })}
          value={form.data.phone.value}
        />
      </Label>
    </div>
  );
};

interface StopsProps {
  tr: TransportRequest;
  openForUpsells: TransportRequestOpenForUpsells;
}

const contactValidators = {
  first_name: (val: string) => !val,
  last_name: (val: string) => !val,
  email: (val: string) => !val || /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(val) === false,
  // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
  phone: (val: string) => val.length < 10 || val.length > 16,
};

const FormFields: React.FC<StopsProps> = ({ tr, openForUpsells }) => {
  const ctx = useTransportContext();
  const { t, i18n } = useTranslationContext();
  const history = useHistory();
  const modal = useModalState();
  const modalSuspicion = useModalState();
  const pickup = getPickupFromTr(tr);
  const delivery = getDeliveryFromTr(tr);
  const params = useTransportParams();
  const locale = i18n.locale.split("-");
  const activeLang = [locale[0]] as TextScanLangs[];
  const { perms, hasSomePerms } = getPerms(ctx.contactType, ctx.status);

  /** CONTACT-PICKUP  */
  const pickupContact = pickup.contact as Contact | undefined;
  const pickupContactForm = useForm({
    initialState: {
      first_name: pickupContact?.first_name || "",
      last_name: pickupContact?.last_name || "",
      email: pickupContact?.email || "",
      phone: pickupContact?.phone || "",
    },
    // Only apply validation when
    validators: ["customer", "pickup"].includes(ctx.contactType || "") ? contactValidators : undefined,
  });

  /** CONTACT-DELIVERY  */
  const deliveryContact = delivery.contact as Contact | undefined;
  const deliveryContactForm = useForm({
    initialState: {
      first_name: deliveryContact?.first_name || "",
      last_name: deliveryContact?.last_name || "",
      email: deliveryContact?.email || "",
      phone: deliveryContact?.phone || "",
    },
    // Only apply validation when
    validators: ["customer", "delivery"].includes(ctx.contactType || "") ? contactValidators : undefined,
  });

  const hasTouchedContactForms = pickupContactForm.isDirty || deliveryContactForm.isDirty;

  /** CONTACT UPDATE  */
  const onContactsSuccess = (): void => {
    ctx.refresh();
    // NOTE: is the upsell forms were not altered, then the confirm price modal never opens.
    // Therefore, simply send user back to tracking page.
    // If any of the instructions were altered do not redirect
    if (!hasTouchedUpsellForms && !hasTouchedInstructions) history.push(`/${params.id}`);
  };
  const contactUpdatePickup = useMutation(coreClient.contacts.update);
  const contactUpdateDelivery = useMutation(coreClient.contacts.update);
  React.useEffect(() => {
    if (!!contactUpdatePickup.data || !!contactUpdateDelivery.data) {
      onContactsSuccess();
    }
  }, [!!contactUpdatePickup.data, !!contactUpdateDelivery.data]);

  /** DELIVERY-PICKUP  */
  const deliveryUpsellForm = useForm({
    initialState: {
      elevator: openForUpsells.elevator?.editable ? delivery.details.elevator : undefined,
      floor_level: openForUpsells.floor_level?.editable ? delivery.details.floor_level : undefined,
      carrying_help: openForUpsells.carrying_help?.editable ? delivery.details.carrying_help : undefined,
    },
  });

  /** UPSELL-PICKUP  */
  const pickupUpsellForm = useForm({
    initialState: {
      elevator: openForUpsells.elevator?.editable ? pickup.details.elevator : undefined,
      floor_level: openForUpsells.floor_level?.editable ? pickup.details.floor_level : undefined,
      carrying_help: openForUpsells.carrying_help?.editable ? pickup.details.carrying_help : undefined,
    },
  });

  const hasTouchedUpsellForms = pickupUpsellForm.isDirty || deliveryUpsellForm.isDirty;

  /** INSTRUCTIONS-PICKUP  */
  const pickupInstructionsForm = useForm({
    initialState: {
      instructions: openForUpsells.instructions?.editable ? pickup.details.instructions : undefined,
    },
  });

  /** INSTRUCTIONS-DELIVERY  */
  const deliveryInstructionsForm = useForm({
    initialState: {
      instructions: openForUpsells.instructions?.editable ? delivery.details.instructions : undefined,
    },
  });

  /** QUOTE CREACTION  */
  const onQuotesSuccess = modal.open;
  const oldQuote = useMutation(priceClient.quotes.create);
  const newQuote = useMutation(priceClient.quotes.create);

  React.useEffect(() => {
    // We only check on oldQuote data
    // Reason: On submit we always fire off a new quote to figure out floor service availability
    // If an upsell field is touched, then we trigger an old quote so we can calculate the diff.
    if (oldQuote.data) {
      onQuotesSuccess();
    }
  }, [!!oldQuote.data]);

  /** SUSPICIONS IN INSTRUCTION FIELD  */
  const suspicions = {
    pickup: getSuspicions(pickupInstructionsForm.data.instructions.value, activeLang),
    delivery: getSuspicions(deliveryInstructionsForm.data.instructions.value, activeLang),
  };
  const hasSuspicion = suspicions.pickup.length || suspicions.delivery.length;
  const hasTouchedInstructions =
    pickupInstructionsForm.data.instructions.isDirty || deliveryInstructionsForm.data.instructions.isDirty;

  /** UPDATE INSTRUCTION/SUSPICIONS */

  const onInstructionSuccess = (): void => {
    ctx.refresh();
    if (!hasTouchedContactForms && !hasTouchedUpsellForms) history.push(`/${params.id}`);
  };
  const instructionsUpdatePickup = useMutation(coreClient.stops.updateInstructionField);
  const instructionsUpdateDelivery = useMutation(coreClient.stops.updateInstructionField);
  React.useEffect(() => {
    if (!!instructionsUpdatePickup.data || !!instructionsUpdateDelivery.data) {
      onInstructionSuccess();
    }
  }, [!!instructionsUpdatePickup.data, !!instructionsUpdateDelivery.data]);

  const onSubmitChanges = (): void => {
    /** Open suspicion modal before submitting so the user sees the modal once */
    const originalParams = getQuoteParamsForTr(tr);
    const newParams = getQuoteParamsForTr(tr);
    newQuote.mutate(newParams);
    if (hasTouchedUpsellForms) {
      oldQuote.mutate(originalParams);
    }
    if (hasSuspicion && !modalSuspicion.isOpen) {
      modalSuspicion.open();
      return;
    }
    if (newParams.pickup.details) {
      const { data } = pickupUpsellForm;
      if (data.elevator.value !== undefined) {
        newParams.pickup.details.elevator = data.elevator.value;
      }

      if (data.floor_level.value !== undefined) {
        newParams.pickup.details.floor_level = data.floor_level.value;
      }

      if (data.carrying_help.value !== undefined) {
        newParams.pickup.details.carrying_help = data.carrying_help.value;
      }
    }

    if (newParams.delivery.details) {
      const { data } = deliveryUpsellForm;
      if (data.elevator.value !== undefined) {
        newParams.delivery.details.elevator = data.elevator.value;
      }

      if (data.floor_level.value !== undefined) {
        newParams.delivery.details.floor_level = data.floor_level.value;
      }

      if (data.carrying_help.value !== undefined) {
        newParams.delivery.details.carrying_help = data.carrying_help.value;
      }
    }
    if (hasTouchedInstructions) {
      instructionsUpdatePickup.mutate({
        id: getIdFromIri(pickup["@id"]) || "",
        instruction_field: pickupInstructionsForm.data.instructions.value || "",
      });
      instructionsUpdateDelivery.mutate({
        id: getIdFromIri(delivery?.["@id"]) || "",
        instruction_field: deliveryInstructionsForm.data.instructions.value || "",
      });
    }

    if (hasTouchedContactForms) {
      contactUpdatePickup.mutate({
        id: getIdFromIri(pickupContact?.["@id"]) || "",
        first_name: pickupContactForm.getValue("first_name"),
        last_name: pickupContactForm.getValue("last_name"),
        email: pickupContactForm.getValue("email"),
        phone: pickupContactForm.getValue("phone"),
      });
      contactUpdateDelivery.mutate({
        id: getIdFromIri(deliveryContact?.["@id"]) || "",
        first_name: deliveryContactForm.getValue("first_name"),
        last_name: deliveryContactForm.getValue("last_name"),
        email: deliveryContactForm.getValue("email"),
        phone: deliveryContactForm.getValue("phone"),
      });
    }
  };

  if (!hasSomePerms) return <Redirect to={`/${params.id}`} />;

  const quoteErr = (oldQuote.error || newQuote.error) as string | undefined;
  const contactErr =
    (contactUpdatePickup.error as CoreViolation | undefined)?.message ||
    (contactUpdateDelivery.error as CoreViolation | undefined)?.message;
  const instructionErr =
    (instructionsUpdatePickup.error as CoreViolation | undefined)?.message ||
    (instructionsUpdateDelivery.error as CoreViolation | undefined)?.message;
  const isLoading =
    oldQuote.isLoading ||
    newQuote.isLoading ||
    contactUpdatePickup.isLoading ||
    contactUpdateDelivery.isLoading ||
    instructionsUpdatePickup.isLoading ||
    instructionsUpdateDelivery.isLoading;
  return (
    <>
      <div className={cn("grid", "grid-cols-1", "gap-4")}>
        {/* PICKUP-RELATED FORM */}
        {(perms?.canUpdatePickupContact || perms?.canAddUpsells) && <h5>{t((d) => d.pickup)}</h5>}
        {perms?.canUpdatePickupContact && <Contact form={pickupContactForm} />}
        {perms?.canAddUpsells && <Upsell form={pickupUpsellForm} />}
        {perms?.canUpdatePickupContact && <Instructions form={pickupInstructionsForm} />}

        {/* DELIVERY-RELATED FORM */}
        {(perms?.canUpdateDeliveryContact || perms?.canAddUpsells) && <h5>{t((d) => d.delivery)}</h5>}
        {perms?.canUpdateDeliveryContact && <Contact form={deliveryContactForm} />}
        {perms?.canAddUpsells && <Upsell form={deliveryUpsellForm} />}
        {perms?.canUpdateDeliveryContact && <Instructions form={deliveryInstructionsForm} />}

        {/* DISPLAY ANY ERRORS DURING FORM SUBMISSION */}
        {quoteErr && <Message type="error">{quoteErr}</Message>}
        {contactErr && <Message type="error">{contactErr}</Message>}
        {instructionErr && <Message type="error">{instructionErr}</Message>}
        {/* FORM BUTTONS */}
        <div className={cn("grid", "sm:grid-cols-2", "grid-cols-1", "gap-4", "mt-4")}>
          <Link to={`/${params.id}`}>
            <Button icon={<IconArrowLeft />} buttonType="primary-outline" className={cn("w-full")} disabled={isLoading}>
              {t((d) => d.cancel_changes)}
            </Button>
          </Link>
          <Button
            disabled={pickupContactForm.hasErrors || deliveryContactForm.hasErrors}
            // NOTE: we do not disable this button at any point.
            // Instead, we rely on core to respond with validation errors.
            // However, we do show red error messages above the inputs if the validation is incorrect.
            buttonType="secondary"
            icon={<IconCheck />}
            loading={isLoading}
            // NOTE: see method for details - sometimes triggers price check, sometimes directly triggers detail update.
            onClick={onSubmitChanges}
          >
            {hasTouchedUpsellForms ? t((d) => d.calculate_price) : t((d) => d.submit)}
          </Button>
        </div>
      </div>
      <UpdateStopDetailsConfirmModal
        {...modal}
        oldQuote={oldQuote.data}
        newQuote={newQuote.data}
        errorQuote={quoteErr}
        isLoading={oldQuote.isLoading || newQuote.isLoading}
      />
      <UpdateInstructionModal
        isOpen={modalSuspicion.isOpen && !newQuote.isLoading}
        close={modalSuspicion.close}
        // When confirming close modal and submit
        closeOnConfirm={() => {
          modalSuspicion.close();
          onSubmitChanges();
        }}
        suspicions={suspicions}
        isFloorLevelServiceAvailable={newQuote.data?.service_advisory?.floor_service !== "not_available"}
      />
    </>
  );
};

export const UpdateStopDetailsForm: React.FC = () => {
  const ctx = useTransportContext();
  const { t } = useTranslationContext();

  // This is the root component and it helps with initializing the form.
  // That is because we can return null until the data is available and then
  // pass the exact pickup + delivery contact details as props so the form is
  // immediately initialized on mount.
  if (!ctx.tr) return null;
  if (!ctx.openForUpsells) return null;

  return (
    <>
      <PageContainer className={cn("py-6", "bg-gray-100")}>
        <h4>{t((d) => d.make_adjustment_cta)}</h4>
        <Spacer h={4} />
        <div>{t((d) => d.make_adjustment_desc)}</div>
      </PageContainer>
      <PageContainer className={cn("py-6")}>
        <FormFields tr={ctx.tr} openForUpsells={ctx.openForUpsells} />
      </PageContainer>
    </>
  );
};
