import { ModalProps, Modal as GenericModal } from "@brenger/react";
import React from "react";
import { ChatModal } from ".";

export const Modal: React.FC<ModalProps & { hasHelpButton?: boolean }> = ({ hasHelpButton, ...rest }) => {
  return (
    <GenericModal
      {...rest}
      afterHeader={
        !hasHelpButton ? undefined : (
          <div className="absolute right-4" style={{ top: "1.1rem" }}>
            <ChatModal />
          </div>
        )
      }
    />
  );
};

export const MpModalContentHead: React.FC<{ title: string; sub?: string }> = ({ title, sub }) => {
  return (
    <div className="mb-6">
      <h3>{title}</h3>
      {sub && (
        <div className={"pt-2"} style={{ fontSize: 20 }}>
          {sub}
        </div>
      )}
    </div>
  );
};
