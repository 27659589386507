import cn from "classnames";
import { useModalState } from "@brenger/react";

import { IconXCircle } from "./";

export const Notification: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const state = useModalState(true);

  if (!state.isOpen) return null;

  return (
    <div
      className={cn(
        "bg-blue-600",
        "px-4",
        "py-2",
        "sticky",
        "top-0",
        "flex",
        "text-white",
        // NOTE about zIndex: This z-index is meant to be quite high in the stacking order.
        // However, should not exceed that of the Dialog component.
        "z-30"
      )}
    >
      <div className={cn("flex-grow")}>{children}</div>
      <button className={cn("ml-4")} onClick={state.close}>
        <IconXCircle />
      </button>
    </div>
  );
};
